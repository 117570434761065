import http from '@/utils/request.js'

// @登录
export const Login = (data = {}) => {
  return http({
    url: '/api_heater/user/login',
    method: 'post',
    data
  })
}

// @修改用户密码
export const UpdatePwd = (data = {}) => {
  return http({
    url: '/api_heater/user/update_pwd',
    method: 'post',
    data
  })
}

// @获取用户信息
export const GetUserInfo = (data = {}) => {
  return http({
    url: '/api_heater/user/get_info',
    method: 'get',
    data
  })
}

// @获取用户列表
export const GetUserList = (data = {}) => {
  return http({
    url: '/api_heater/user/get_list',
    method: 'get',
    data
  })
}

// @获取角色列表
export const GetRoleList = (data = {}) => {
  return http({
    url: '/api_heater/user/get_role_list',
    method: 'get',
    data
  })
}

// @更新用户状态
export const UpdateUserInfo = (data = {}) => {
  return http({
    url: '/api_heater/user/update_info',
    method: 'post',
    data
  })
}

// @添加用户
export const AddUser = (data = {}) => {
  return http({
    url: '/api_heater/user/add_user',
    method: 'post',
    data
  })
}

// @修改用户信息
export const EditUser = (data = {}) => {
  return http({
    url: '/api_heater/user/update_info',
    method: 'post',
    data
  })
}

// @获取项目列表
export const GetProjectList = (data = {}) => {
  return http({
    url: '/api_heater/project/get_manage_list',
    method: 'get',
    data
  })
}

// @项目列表
export const AddProject = (data = {}) => {
  return http({
    url: '/api_heater/project/add',
    method: 'post',
    data
  })
}

// @更新项目信息
export const UpdateProject = (data = {}) => {
  return http({
    url: '/api_heater/project/update',
    method: 'post',
    data
  })
}

// @删除项目
export const DelProject = (data = {}) => {
  return http({
    url: '/api_heater/project/del',
    method: 'post',
    data
  })
}

// @获取数据中心顶部四个色块数据
export const HomeData = (data = {}) => {
  return http({
    url: '/api_heater/device/get_home_data',
    method: 'get',
    data
  })
}

// @获取异常列表
export const GetErrorList = (data = {}) => {
  return http({
    url: '/api_heater/warn/warn_history_list',
    method: 'get',
    data
  })
}

// @获取已配置的系统
export const GetConfigSystem = (data = {}) => {
  return http({
    url: '/api_heater/manage/device/get_config',
    method: 'get',
    data
  })
}

// @获取预设预警
export const GetPreWarning = (data = {}) => {
  return http({
    url: '/api_heater/device/get_manage_list_warn',
    method: 'get',
    data
  })
}

// @更新预警 默认预警 更新预警
export const UpdateWarning = (data = {}) => {
  const p = { route_index: 7001 }
  Object.assign(p, data)
  return http({
    url: '/api_heater/device/update_manage_warn',
    method: 'post',
    data: p
  })
}

// @更新预警 自定义预警 更新预警
export const UpdateWarning2 = (data = {}) => {
  const p = { route_index: 7002 }
  Object.assign(p, data)
  return http({
    url: '/api_heater/device/update_manage_warn',
    method: 'post',
    data: p
  })
}

// @获取运营商列表
export const GetOperatorList = (data = {}) => {
  return http({
    url: '/api_heater/operator/get_manage_project_device_list',
    method: 'get',
    data
  })
}

// @添加运营商
export const AddOperator = (data = {}) => {
  return http({
    url: '/api_heater/operator/add',
    method: 'post',
    data
  })
}

// @修改运营商
export const EditOperator = (data = {}) => {
  return http({
    url: '/api_heater/operator/update',
    method: 'post',
    data
  })
}

// @删除运营商
export const DelOperator = (data = {}) => {
  return http({
    url: '/api_heater/operator/del',
    method: 'get',
    data
  })
}

// @添加预设参数
export const AddPreConfig = (data = {}) => {
  return http({
    url: '/api_heater/device/add_auto_config_param',
    method: 'post',
    data
  })
}

// @更新预设参数
export const UpdatePreConfig = (data = {}) => {
  return http({
    url: '/api_heater/device/update_auto_config_param',
    method: 'post',
    data
  })
}

// @获取系统预设参数
export const GetPreConfig = (data = {}) => {
  return http({
    url: '/api_heater/device/get_auto_config_param',
    method: 'get',
    data
  })
}

// @删除配置信息
export const DelPreConfig = (data = {}) => {
  return http({
    url: '/api_heater/device/delete_auto_config_param',
    method: 'post',
    data
  })
}

// @删除自定义预警
export const DelCustomWarning = (data = {}) => {
  return http({
    url: '/api_heater/device/delete_manage_warn',
    method: 'post',
    data
  })
}

// @添加自定义预警
export const AddCustomWarn = (data = {}) => {
  return http({
    url: '/api_heater/device/add_manage_warn',
    method: 'post',
    data
  })
}

// @配置已配置的系统
export const ConfigSystem = (data = {}) => {
  return http({
    url: '/api_heater/manage/device/config',
    method: 'post',
    data
  })
}

/**
 * @和 config 是一样的接口, 只是这个不必传运营商ID
 * 未配置设备里面用, 和ConfigSystem2一样,携带权限id
 */
export const ConfigSystem2 = (data = {}) => {
  const p = { route_index: 5001 }
  Object.assign(p, data)
  return http({
    url: '/api_heater/manage/device/update_config',
    method: 'post',
    data: p
  })
}

/**
 * @和 config 是一样的接口, 只是这个不必传运营商ID
 * 已配置设备里面用, 和ConfigSystem2接口一样,携带权限id
 */
export const ConfigSystem3 = (data = {}) => {
  const p = { route_index: 5002 }
  Object.assign(p, data)
  return http({
    url: '/api_heater/manage/device/update_config',
    method: 'post',
    data: p
  })
}

// @初始化设备
export const InitConfig = (data = {}) => {
  return http({
    url: '/api_heater/manage/device/config_init',
    method: 'post',
    data
  })
}

// @获取运营商列表
export const GetOperatorData = (data = {}) => {
  return http({
    url: '/api_heater/operator/get_list',
    method: 'get',
    data
  })
}

// @获取未配置系统列表
export const GetNotConfigList = (data = {}) => {
  return http({
    url: '/api_heater/manage/device/get_not_config',
    method: 'get',
    data
  })
}

// @获取运营商用水
export const GetUseWater = (data = {}) => {
  return http({
    url: '/api_heater/operator/get_using_water_history',
    method: 'get',
    data
  })
}

// @获取工作时长
export const GetWorkTime = (data = {}) => {
  return http({
    url: '/api_heater/operator/get_machine_work_history',
    method: 'get',
    data
  })
}

// @获取机组
export const GetCrew = (data = {}) => {
  return http({
    url: '/api_heater/machine/get_list',
    method: 'get',
    data
  })
}

// @添加机组
export const AddCrew = (data = {}) => {
  return http({
    url: '/api_heater/machine/add',
    method: 'post',
    data
  })
}

// @删除机组
export const DelCrew = (data = {}) => {
  return http({
    url: '/api_heater/machine/del',
    method: 'post',
    data
  })
}

// @编辑机组
export const UpdateCrew = (data = {}) => {
  return http({
    url: '/api_heater/machine/update',
    method: 'post',
    data
  })
}

// @获取水箱
export const GetWaterCase = (data = {}) => {
  return http({
    url: '/api_heater/machine/get_list_wbox',
    method: 'get',
    data
  })
}

// @添加水箱
export const AddWaterCase = (data = {}) => {
  return http({
    url: '/api_heater/machine/add_wbox',
    method: 'post',
    data
  })
}

// @删除水箱
export const DelWaterCase = (data = {}) => {
  return http({
    url: '/api_heater/machine/del_wbox',
    method: 'post',
    data
  })
}

// @更新水箱
export const UpdateWaterCase = (data = {}) => {
  return http({
    url: '/api_heater/machine/update_wbox',
    method: 'post',
    data
  })
}

// @获取水泵
export const GetWaterPipe = (data = {}) => {
  return http({
    url: '/api_heater/machine/get_list_pipe',
    method: 'get',
    data
  })
}

// @添加供水管道
export const AddProvidePipe = (data = {}) => {
  return http({
    url: '/api_heater/machine/add_pipe',
    method: 'post',
    data
  })
}

// @删除供水管
export const DelProvidePipe = (data = {}) => {
  return http({
    url: '/api_heater/machine/del_pipe',
    method: 'post',
    data
  })
}

// @更新供水管道
export const UpdateProvidePipe = (data = {}) => {
  return http({
    url: '/api_heater/machine/update_pipe',
    method: 'post',
    data
  })
}

// @添加补水阀
export const AddWaterValve = (data = {}) => {
  return http({
    url: '/api_heater/machine/add_valve',
    method: 'post',
    data
  })
}

// @删除补水阀
export const DelWaterValve = (data = {}) => {
  return http({
    url: '/api_heater/machine/del_valve',
    method: 'post',
    data
  })
}

// @更新补水阀
export const UpdateWaterValve = (data = {}) => {
  return http({
    url: '/api_heater/machine/update_valve',
    method: 'post',
    data
  })
}

// @获取控制计划
export const GetControlTask = (data = {}) => {
  return http({
    url: '/api_heater/device/get_auto_condition_control',
    method: 'get',
    data
  })
}

// @添加控制计划
export const AddControlTask = (data = {}) => {
  return http({
    url: '/api_heater/device/add_auto_condition_control',
    method: 'post',
    data
  })
}

// @删除控制规则
export const DelControl = (data = {}) => {
  return http({
    url: '/api_heater/device/del_auto_condition_control',
    method: 'post',
    data
  })
}

// @操控机组
export const ControlDevice = (data = {}) => {
  return http({
    url: '/api_heater/device/control_device',
    method: 'post',
    data
  })
}

// @通过id 获取机组
export const GetCrewInfoById = (data = {}) => {
  return http({
    url: '/api_heater/machine/get_info',
    method: 'get',
    data
  })
}

// @通过id 获取水箱
export const GetWaterBoxById = (data = {}) => {
  return http({
    url: '/api_heater/machine/get_wbox_info',
    method: 'get',
    data
  })
}

// @通过id获取供水管
export const GetPumpById = (data = {}) => {
  return http({
    url: '/api_heater/machine/get_pipe_info',
    method: 'get',
    data
  })
}

// @通过id获取补水阀
export const GetValveById = (data = {}) => {
  return http({
    url: '/api_heater/machine/get_valve_info',
    method: 'get',
    data
  })
}

// @操作记录
export const GetControlList = (data = {}) => {
  return http({
    url: '/api_heater/device/control_device_detail',
    method: 'get',
    data
  })
}

// @获取历史数据头 列表
export const GetHistoryList = (data = {}) => {
  return http({
    url: '/api_heater/device/get_history_data_title',
    method: 'get',
    data
  })
}

// @获取历史数据
export const GetHistoryData = (data = {}) => {
  return http({
    url: '/api_heater/device/get_history_data_with_title',
    method: 'post',
    data
  })
}

// @获取系统补水 没有用到
export const GetProvideWater = (data = {}) => {
  return http({
    url: '/api_heater/operator/get_replenish_water_history',
    method: 'get',
    data
  })
}

// @添加角色
export const AddRole = (data = {}) => {
  return http({
    url: '/api_heater/user/add_role',
    method: 'post',
    data
  })
}

// @修改角色
export const EditRole = (data = {}) => {
  return http({
    url: '/api_heater/user/update_role',
    method: 'post',
    data
  })
}

// @给系统配置管理员
export const SetSystemWorker = (data = {}) => {
  return http({
    url: '/api_heater/project/update_woker',
    method: 'post',
    data
  })
}

// @单独获取运营商列表
export const GetOperatorList2 = (data = {}) => {
  return http({
    url: '/api_heater/operator/get_list',
    method: 'get',
    data
  })
}

// @启用勾选的控制规则
export const SetUseRule = (data = {}) => {
  return http({
    url: '/api_heater/device/save_auto_condition_control',
    method: 'post',
    data
  })
}

// @获取首页四个盒子实时数据
export const GetHomeData = (data = {}) => {
  return http({
    url: '/api_heater/device/get_device_home_data',
    method: 'get',
    data
  })
}

// @获取系统列表 echart 数据
export const GetHistoryHeater = (data = {}) => {
  return http({
    url: '/api_heater/device/get_history_heater',
    method: 'get',
    data
  })
}

// @获取移动端账号列表
export const GetAppAccount = (data = {}) => {
  return http({
    url: '/api_heater/user/get_app_account',
    method: 'get',
    data
  })
}

// @添加app账号
export const AddAppAccount = (data = {}) => {
  return http({
    url: '/api_heater/user/add_app_account',
    method: 'post',
    data
  })
}

// @更新移动端账号信息
export const UpdateAppUser = (data = {}) => {
  return http({
    url: '/api_heater/user/update_app_account',
    method: 'post',
    data
  })
}

// @获取设备配置
export const GetDeviceCfgApi = (data = {}) => {
  return http({
    url: '/api_heater/manage/device/get_config',
    method: 'get',
    data
  })
}

// @获取环境温度
export const GetEnvTempApi = (data = {}) => {
  return http({
    url: '/api_heater/machine/get_env_temp',
    method: 'get',
    data
  })
}

// @设置环境温度
export const SetEnvTempApi = (data = {}) => {
  return http({
    url: '/api_heater/machine/update_env_temp',
    method: 'post',
    data
  })
}

// @获取通道列表
export const GetChannelListApi = (data = {}) => {
  return http({
    url: '/api_heater/comm/channel_control/get_channel_control',
    method: 'get',
    data
  })
}

// @获取管道任务
export const GetChannelTaskListApi = (data = {}) => {
  return http({
    url: '/api_heater/comm/channel_control/get_channel_control_auto',
    method: 'get',
    data
  })
}

// @更新通道
export const UpdateChannelApi = (data = {}) => {
  return http({
    url: '/api_heater/comm/channel_control/update_channel_control',
    method: 'post',
    data
  })
}

// @添加通道任务
export const AddChannelTaskApi = (data = {}) => {
  return http({
    url: '/api_heater/comm/channel_control/add_channel_control_auto',
    method: 'post',
    data
  })
}

// @更新通道任务
export const UpdateChannelTaskApi = (data = {}) => {
  return http({
    url: '/api_heater/comm/channel_control/update_channel_control_auto',
    method: 'post',
    data
  })
}

// @获取手机号推送
export const GetPushSettingApi = (data = {}) => {
  return http({
    url: '/api_heater/device/get_push',
    method: 'get',
    data
  })
}

// @修改推送
export const UpdatePushApi = (data = {}) => {
  return http({
    url: '/api_heater/device/update_push',
    method: 'post',
    data
  })
}

// @获取传感器数据
export const GetSensorDataApi = (data = {}) => {
  return http({
    url: '/api_heater/device/get_data_board_power',
    method: 'get',
    data
  })
}

// @获取项目列表
export const GetProjectListV2Api = (data = {}) => {
  return http({
    url: '/api_heater/project/get_project_list',
    method: 'get',
    data
  })
}

// @根据项目获取系统信息
export const GetSysInfoListApi = (data = {}) => {
  return http({
    url: '/api_heater/device/get_project_device_home_data',
    method: 'get',
    data
  })
}

// @设备列表排序
export const SortDeviceApi = (data = {}) => {
  return http({
    url: '/api_heater/device/update_device_sort',
    method: 'post',
    data
  })
}

// @获取首页
export const GetHomeDataApi = (data = {}) => {
  return http({
    url: '/api_heater/device/get_device_home_data_new',
    method: 'get',
    data
  })
}

// @获取系统预警列表
export const GetSysWarnListApi = (data = {}) => {
  return http({
    url: '/api_heater/warn/device_warn_list',
    method: 'get',
    data
  })
}

// @添加项目预警人员
export const AddWarnTelApi = (data = {}) => {
  return http({
    url: '/api_heater/warn/cover_device_warn_tel',
    method: 'post',
    data
  })
}

// @获取设备预警可用通道
export const GetWarnChannelApi = (data = {}) => {
  return http({
    url: '/api_heater/warn/get_available_channel',
    method: 'get',
    data
  })
}

// @添加预警
export const AddWarnApi = (data = {}) => {
  return http({
    url: '/api_heater/warn/add_warn_desc',
    method: 'post',
    data
  })
}

// @获取可设置的参数
export const GetWarnTypeApi = (data = {}) => {
  return http({
    url: '/api_heater/warn/get_available_warn_desc',
    method: 'get',
    data
  })
}

// @更新预警里面的设备
export const UpdateWarnDeviceApi = (data = {}) => {
  return http({
    url: '/api_heater/manage/device/update_device',
    method: 'post',
    data
  })
}

// @删除预警
export const DelWarnApi = (data = {}) => {
  return http({
    url: '/api_heater/warn/delete_warn',
    method: 'post',
    data
  })
}

// @获取预警内容
export const GetWarnContentApi = (data = {}) => {
  return http({
    url: '/api_heater/warn/warn_desc_content',
    method: 'get',
    data
  })
}
