<template>
  <div class="aside">
    <div class="logo_box">
      <img src="../../assets/logo.png" />
    </div>
    <!-- 递归遍历的菜单 -->
    <el-scrollbar class="menu_box">
      <Menu />
    </el-scrollbar>

    <!-- 版本号 -->
    <div class="version">v4.0.0</div>
  </div>
</template>

<script>
import Menu from "./Menu.vue";
export default {
  components: { Menu },
};
</script>

<style lang="scss" scoped>
.aside {
  height: 100%;

  .logo_box {
    height: 140px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      // width: 201px;
      // height: 46px;
      width: 180px;
      height: 41px;
    }
  }

  .menu_box {
    width: 100%;
    height: calc(100% - 140px - 80px);
    overflow: hidden;
  }

  .version {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}
</style>
<style lang="scss">
.aside {
  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }

  .el-menu {
    border-right: none !important;
  }
}
</style>
