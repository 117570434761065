import Layout from '@/components/layout/index.vue'
export default [
  /**
   * 这里的路由是用来 侧边栏组件 递归 用的
   * 递归的条件是 当前路由有子路由 且 子路由的长度大于1 或者 当前路由的子路由的长度 为 1, 且 子路由的子路由 存在 且 长度不为0
   * 使用注意: 如果当前路由只有一个子路由, 会优先取子路由的 meta 中的 name 作为侧边栏的名字
   * 如果子路由的子路由的children的长度大于 0, 那么会直接取当前路由的 meta 中的 name
   * 所以: 当子路由有一个以上时, 必须 给当前路由设置 meta中的name属性
   * nochche: 为true 时不添加缓存, notag 为 true 时, 不添加标签
   *
   * 需要缓存的 路由, 需要在路由上加  name 属性, 文件里面也要加上相同的 name 属性
   */
  // 项目列表
  {
    path: '/system',
    component: Layout,
    meta: {
      name: '系统信息',
      icon: 'iconfont icon-xiangmuliebiao'
    },
    children: [
      {
        name: 'system_preview',
        path: '/system_preview',
        component: () => import('@/views/system/system_preview/system_preview.vue'),
        meta: {
          id: 2,
          name: '项目信息'
        }
      },
      {
        name: 'system_info',
        path: '/system_info',
        component: () => import('@/views/system/system_info/system_info.vue'),
        hidden: true,
        meta: {
          id: 2,
          name: '系统信息'
        }
      }
    ]
  },

  // 项目信息
  {
    path: '/project_list',
    component: Layout,
    meta: {
      name: '项目列表',
      icon: 'iconfont icon-xiangmuxinxi'
    },
    children: [
      {
        name: 'project_list',
        path: '/project_list',
        component: () => import('@/views/project_list/project_list.vue'),
        meta: {
          id: 3,
          name: '项目列表'
        }
      }
    ]
  },

  // 异常统计
  {
    path: '/anomaly_statistics',
    component: Layout,
    meta: {
      name: '异常统计',
      icon: 'iconfont icon-yichangtongji'
    },
    children: [
      {
        name: 'anomaly_statistics',
        path: '/anomaly_statistics',
        component: () =>
          import('@/views/anomaly_statistics/anomaly_statistics.vue'),
        meta: {
          id: 4,
          name: '异常统计'
        }
      }
    ]
  },

  // 系统管理 >> 未配置系统 + 已配置系统
  {
    path: '/system_config',
    component: Layout,
    meta: {
      name: '系统管理',
      icon: 'iconfont iconfont icon-xitongguanli'
    },
    children: [
      {
        name: 'not_config',
        path: '/system/not_config',
        component: () => import('@/views/system_config/not_config/not_config.vue'),
        meta: {
          id: 5001,
          name: '未配置系统'
        }
      },
      {
        path: '/system/system_list',
        name: 'system_list',
        component: () => import('@/views/system_config/system_list/system_list.vue'),
        meta: {
          id: 5002,
          name: '已配置系统'
        }
      }
    ]
  },

  // 预警设置
  {
    path: '/warn_setting',
    component: Layout,
    meta: {
      name: '预警设置',
      icon: 'iconfont icon-yujingshezhi'
    },
    children: [
      {
        path: '/warn_setting',
        name: 'warn_setting',
        component: () =>
          import(
            '@/views/warn_setting/warn_setting.vue'
          ),
        meta: {
          id: 7,
          name: '预警设置'
        }
      },
    ]
  },

  // 运营商管理
  {
    path: '/operator',
    component: Layout,
    meta: {
      name: '运营商管理',
      icon: 'iconfont icon-yunyingshangguanli'
    },
    children: [
      {
        name: 'operator',
        path: '/operator',
        component: () => import('@/views/operator/operator.vue'),
        meta: {
          id: 8,
          name: '运营商管理'
        }
      }
    ]
  },

  // 用户权限
  {
    path: '/permission',
    component: Layout,
    meta: {
      name: '用户权限',
      icon: 'iconfont icon-yonghuquanxian'
    },
    children: [
      {
        name: 'user',
        path: '/permission/user',
        component: () => import('@/views/permission/user/user.vue'),
        meta: {
          id: 9001,
          name: '用户管理'
        }
      },
      {
        path: '/permission/permission_distribution',
        component: () =>
          import(
            '@/views/permission/permission_distribution/permission_distribution.vue'
          ),
        name: 'permission_distribution',
        meta: {
          id: 9002,
          name: '权限分配'
        }
      }
    ]
  }
]
